import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const InstallOperaPage = () => (
  <Layout>
    <SEO title="How to install on Opera" />
    <section className="text-section">
      <h2>How to install Box-scores for Opera</h2>
      <p>
        Opera users can install the Chrome version of Box-scores as follows:
      </p>
      <ol className="text">
        <li>
          Get the{" "}
          <a href="https://addons.opera.com/en/extensions/details/install-chrome-extensions/">
            Install Chrome Extensions
          </a>{" "}
          add-on for Opera
        </li>
        <li>
          Check the add-on is active in the{" "}
          <a href="opera://extensions">Opera extensions menu</a>
        </li>
        <li>
          Install Box-scores from the{" "}
          <a href="https://chrome.google.com/webstore/detail/basketball-box-scores/mfmkedeaebcckihpinmhkadoagdbifaa">
            Chrome Store Page
          </a>
        </li>
      </ol>
      <p>
        Because Opera and Chrome are both built on Chromium, the Opera and
        Chrome versions of Box-scores are identical.
      </p>

      <p>
        You can enjoy the same great Box-scores experience with the Chrome
        version in Opera as with the eventual Opera version.
      </p>
    </section>
  </Layout>
)

export default InstallOperaPage
